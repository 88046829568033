.MySearchBar-SearchBar-173 {
    height: 39px !important;
}
.ActiveLogMain {
    display: flex;
}

.ActiveTitle {
    color: #645E5E;
    font-weight: 700;
}

.ActiveComment {
    color: #A5A1A1;
}
.Account-btn {
    background-color: #217561 !important;
    color: white  !important;
    border-radius: 25px !important;
    font-size: 14px !important;
    padding: 6px 17px !important;
}
.ActiveverticalLine {
    border-left: 3px solid gray;
    height: 50px;
    margin-left: 60px;
}

.approvalMain {
    display: flex;
    justify-content: center;
    margin-bottom: 30px !important;
    align-items: flex-end;
}

.wrapper:after {
    content: " ";
    width: 100px;
    height: 2px;
    margin: -4px 6px;
    vertical-align: super;
    background-color: grey;
    display: inline-block;
}
.inst-btn {
    text-transform: uppercase !important;
    margin: 60px 0 0 320px !important;
    -moz-border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    border-radius: 20px !important;
    outline: 0 !important;
    font-size: 14px !important;
    color: white !important;
    padding: 5px !important;
    margin: 0 !important;
    font-family: "arial" !important;
    border: 0 !important;
}
.approvalIcon {
    margin-left: 45px;
}

div.searchBarHeight > div:first-child {
    height: 38px !important;
}

div.searchHeight > div:first-child {
    height: 40px !important;
}

.line {
    position: relative;
}

.line::after {
    content: "";
    height: 2px;
    width: 1100px;
    background: black;
    border-radius: 23%;
    position: absolute;
    top: 55%;
}