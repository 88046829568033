.courseDetailMobileRoot {
    width: 100%;
    height: 100vh;
    padding-bottom: 115px;
    overflow-y: scroll;
    position: relative;

    .courseDetailMobileArticle {
        display: flex;
        // margin: 20px;
        // margin-bottom: 0px;
        position: fixed;
        background: white;
        width: 100%;
        padding: 10px 20px;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 45px;
        max-height: auto;

        .courseDetailMobileBackButton {
            width: 56px;
            font-size: 25px;
            color: black;
            cursor: pointer;
        }

        .courseDetailMobileTitleContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            min-height: 45px;
            max-height: auto;

            .courseDetailMobileTitle {
                font-size: 16px;
                font-weight: 700;
                color: #1d1719;
            }

            .courseDetailMobileSlideTitle {
                font-size: 11px;
                color: #1d1719;
            }
        }

        .courseDetailMobileFeedbackBtnContainer {
            display: flex;
            height: 100%;
            min-height: 45px;
            align-items: flex-end;

            .courseDetailMobileFeedbackButton {
                background: rgba(19, 80, 152, 0.1);
                border-radius: 5px;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: black;
            }
        }
    }

    .courseDetailMobileLoaderContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        min-height: 90vh;
        height: 100%;
        align-items: center;
        background: transparent;
    }

    .courseDetailMobileButtonContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;

        .courseDetailMobileBtnStyle {
            background: #135098;
            color: white;
            font-size: 12px;
            font-weight: 500;
            border-radius: 5px;
            padding: 4px 7px 4px 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: capitalize;
            cursor: pointer;
        }
    }

    .courseDetailslidesBox {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 90;
    }
}
