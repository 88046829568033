.backdrop_container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1500;
}

.modal_container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1500;
    border-radius: 10px;
}

.edit_modal_container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1500;
    border-radius: 10px;
    width: 630px;
    max-height: 710px;
    overflow-y: auto;
}

.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.logoutmodal_header {
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 10px;
}

.modal_header>div {
    margin: 0 5px;
}

.modal_body {
    /* width: 630px; */
    /* padding: 0px 20px 10px; */
    /* max-height: 560px; */
    overflow-y: auto;
}

.modal_body::-webkit-scrollbar {
    width: 5px;
    background-color: #bbb;
}

.modal_body::-webkit-scrollbar-thumb {
    background: #8392AB;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

hr {
    background: #cfcfcf;
    height: 1px;
    border-width: 0px;
}

.modal_close_icon {
    cursor: pointer;
    margin: 0;
}

.modal_title>p {
    margin: 8px 0px;
    color: #2645ad;
    font-weight: 500;
    font-size: 20px;

}

.MuiTab-textColorPrimary .Mui-selected {
    color: #FFFFFF;
}

.MuiTab-root {
    /* min-width: 315px ! important; */
    min-height: 52px;
    max-width: 315px;
    max-height: 52px;
}

@media (max-width: 600px) {
    .modal_title>p {
        font-size: 8px;
    }

    .MuiTab-root {
        min-width: 185px ! important;
        min-height: 35px;
        max-width: 185px;
        max-height: 35px;
    }

    .MuiTabs-scroller {
        position: "";
    }
}