.css-heg063-MuiTabs-flexContainer {
    justify-content: space-around !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    line-height: 14px !important;
}

.css-13xfq8m-MuiTabPanel-root {
    padding: 24px 0px !important;
}

.textMargin {
    margin-bottom: 20px !important;
}

.textMargin input {
    height: 15px !important;
}

.textMargin11 input {
    height: 26px !important;
}

.bottomIcon {
    background: #595fe7;
    color: white;
    cursor: pointer;
    border-radius: 0px 0px 87px 87px;
    width: 29px;
    margin: auto;
    height: 20px;
}

.bottomIcon svg {
    width: 32px;
    margin-top: -11px;
    font-size: 20px;
    margin-left: -2px;
}

.bottomIcon path {
    color: white;
}

.lineClass {
    margin-bottom: 26px;
    position: relative;
    overflow: inherit;
    display: block;
}

.lineClass::after {
    content: "";
    position: absolute;
    bottom: -27px;
    height: 20px;
    box-sizing: border-box;
    left: 50%;
    width: 0;
    border-left: 2px solid #bbbbcc;
}

.topIcon {
    background: #595fe7;
    color: white;
    cursor: pointer;
    width: 30px;
    margin: auto;
    border-radius: 330px 330px 0px 0px;
}

.topIcon svg {
    width: 32px;
    margin-bottom: -4px;
    font-size: 20px;
}

.topIcon path {
    color: white;
}

.leftIcon path {
    color: white;
}

.leftIcon {
    background: #595fe7;
    color: white;
    cursor: pointer;
    width: 17px;
    margin: auto;
    border-radius: 100px 0px 0px 100px;
    height: 29px;
}

.leftIcon svg {
    font-size: 20px;
    margin-left: 1px;
}

.cardHeaderTitle {
    display: flex;
    justify-content: space-around;
    margin-top: 4px;
}

.titleName {
    font-weight: 600;
    margin-left: 10px;
}

.cardInnerTitle {
    color: #2a292e;
    font-size: 13px;
    display: flex;
}

.cardInnberDate {
    font-size: 11px;
    margin-left: -8px;
}

.cardInnberMobile {
    font-size: 11px;
}

.dot {
    height: 5px;
    width: 5px;
    background-color: #8d8b97;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
}

.card_padding {
    padding-top: 0px !important;
}

.buttonname {
    color: white;
    background: #977c4f;
    border: none;
    text-align: center;
    padding: 10px 20px;
}

.rounded-corners {
    border-radius: 25px;
}

.css-o69gx8-MuiCardMedia-root {
    object-fit: contain !important;
}

.myTitle {
    font-size: 18px !important;
}